import React from 'react';
import wydr from '@welldone-software/why-did-you-render';
import { store } from '@core/store';

if (store.config.environment() === 'development') {
  const whyDidYouRender = wydr;
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    onlyLogs: true,
    titleColor: 'red',
    diffNameColor: 'green',
    collapseGroups: false,
    diffPathColor: 'yellow',
    logOwnerReasons: true,
    logOnDifferentValues: true,
    trackHooks: true,
  });
}
