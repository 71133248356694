import { mapValuesKey } from '@udecode/zustood';
import { authStore } from '@core/store/auth';
import { configStore } from '@core/store/config';
import { fullscreenStore } from '@core/store/fullscreen';
import { modalStore } from '@core/store/modal';
import { sidebarStore } from '@core/store/sidebar';
import { themeStore } from '@core/store/theme';
import { languageStore } from '@core/store/language';

// Global store
export const rootStore = {
  theme: themeStore,
  modal: modalStore,
  sidebar: sidebarStore,
  auth: authStore,
  config: configStore,
  fullscreen: fullscreenStore,
  language: languageStore,
};

export const useStore = () => mapValuesKey('use', rootStore);
export const store = mapValuesKey('get', rootStore);
export const actions = mapValuesKey('set', rootStore);
