import { createStore } from '@udecode/zustood';

export const fullscreenStore = createStore('fullscreen')(
  {
    isOpen: false,
  },
  { devtools: { enabled: true } }
).extendActions((set, get) => ({
  open: () => {
    set.isOpen(true);
  },
  close: () => {
    set.isOpen(false);
  },
  toggle: () => {
    set.isOpen(!get.isOpen());
  },
}));
