import { Router } from '@remix-run/router';
import { createStore } from '@udecode/zustood';
import { Config } from '@core/types';

export const configStore = createStore('config')<Config>({
  apiUrl: '',
  wsUrl: '',
  configUrl: '',
  environment: '',
  storageKeyPrefix: '',
  projectName: '',
  defaultRoute: '',
  router: {} as Router,
}).extendActions((set) => ({
  initialize: (config: Config) => {
    set.state(() => config);
  },
}));
