import { createStore } from '@udecode/zustood';
import jwtDecode from 'jwt-decode';

interface AuthStore {
  tokens:
    | {
        access: string;
        refresh: string;
      }
    | undefined;
  isLogged: boolean;
  claims: Claims | undefined;
}
export type Claims = {
  id: string;
  role: string;
  orgId: string;
};

interface JwtPayload extends Claims {
  expires: number;
}

export const authStore = createStore('auth')<AuthStore>(
  {
    isLogged: false,
    tokens: undefined,
    claims: undefined,
  },
  {
    devtools: { enabled: true },
    persist: {
      enabled: true,
      name: 'react-template-tokens',
      getStorage: () => window.localStorage,
    },
  }
).extendActions((set) => ({
  setTokens: (accessToken: string, refreshToken: string) => {
    if (accessToken === '' || refreshToken === '') {
      throw new Error('Empty access/refresh tokens');
    }
    const claims = jwtDecode<JwtPayload>(accessToken);
    set.state((state) => ({
      ...state,
      tokens: { access: accessToken, refresh: refreshToken },
      claims,
      isLogged: true,
    }));
  },
  removeTokens: () => {
    set.state(() => ({ isLogged: false, tokens: undefined, claims: undefined }));
  },
}));
